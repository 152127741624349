import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { ApplicationState, setToken as setTokenAction } from 'store';
const queryString = require('query-string');

export const useToken = () => {
  const [token, setToken] = useState<string | undefined>();
  const { token: tokenFromParams } = queryString.parse(useLocation().search);
  const tokenFromStore = useSelector((state: ApplicationState )=> state?.token?.token);
  const dispatch = useDispatch();

  useEffect(() => {
    if (tokenFromParams && (tokenFromParams !== tokenFromStore)) {
      dispatch(setTokenAction(tokenFromParams));
      setToken(tokenFromParams);
    } else {
      setToken(tokenFromStore);
    }
  }, [tokenFromParams, tokenFromStore]);

  return token;

}

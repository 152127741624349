import React from 'react';

interface Props {
  on: any;
  children: React.ReactElement[];
}

export const Switch = ({ on, children }: Props) => (
  <>
    {React.Children.map(children, (child) =>
      React.cloneElement(child, { data: on })
    )}
  </>
);

import { METHODS } from 'http';
import { Action, Reducer } from 'redux';
import { AppThunkAction } from './';

export enum LoadingStatus {
    NotInitialized,
    Loading,
    Loaded,
    Unauthorized
}

export interface AppStatusState {
    loadingStatus: LoadingStatus;
    appStatus?: AppStatus;
}

export interface AppStatus {
    plan: Plan;
    availablePlans: Plan[];
    sharingPlanWith: string;
    updatePermissionsUrl: string;
    upgradeRequired: boolean;
    integrationRequired: boolean;
    iossNumber: string;
    shopifyEuTaxSetupConfirmed: boolean;
    shopifyAdminUrl: string;
    integrationKey: string;
    errorMessage: string | '';
}

export interface Plan {
    id: number;
    name: string;
    trialDays: number;
    isTest: boolean;
    price: number;
    description: string;
    footer: string;
    active: boolean;
    isDev: boolean;
    isPopular: boolean;
}

interface RequestAppStatusAction {
    type: 'REQUESTED_APP_STATUS';
}

interface ReceiveAppStatusAction {
    type: 'RECEIVED_APP_STATUS';
    appStatus: AppStatus;
}


type KnownAction = RequestAppStatusAction | ReceiveAppStatusAction;

export const actionCreators = {
    requestStatus: (): AppThunkAction<KnownAction> => (dispatch, getState) => {

        const appState = getState();

        if (appState?.home?.loadingStatus === LoadingStatus.NotInitialized) {

            const urlParams = new URLSearchParams(window.location.search);
            const token = urlParams.get('token');

            if (!token) return;

            dispatch({ type: 'REQUESTED_APP_STATUS' });

            const fetchOptions = {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                }
            }

            fetch(`api/Account/Installationstatus`, fetchOptions)
                .then(response => response.json() as Promise<AppStatus>)
                .then(data => {
                    dispatch({ type: 'RECEIVED_APP_STATUS', appStatus: data });
                })


        }
    }
};

const getUnloadedState = (): AppStatusState => ({
    loadingStatus: LoadingStatus.NotInitialized,
    appStatus: undefined,
});

export const homeReducer: Reducer<AppStatusState> = (
    state: AppStatusState | undefined,
    incomingAction: Action
): AppStatusState => {

    if (state === undefined) {
        return getUnloadedState();
    }

    const action = (incomingAction || {}) as KnownAction;

    switch (action.type) {

        case 'REQUESTED_APP_STATUS':
            return {
                ...state,
                loadingStatus: LoadingStatus.Loading,
            };

        case 'RECEIVED_APP_STATUS':
            return {
                ...state,
                loadingStatus: LoadingStatus.Loaded,
                appStatus: action.appStatus,
            }

        default:
            return state;
    }

};

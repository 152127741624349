import React from 'react';

interface Props {
  data?: any;
  match?: (any | undefined);
  component?: JSX.Element;
}

export const Case: React.FunctionComponent<Props> = ({
  data,
  match = [],
  children,
  component,
}) => <>{match.includes(data) && (component || children)}</>;

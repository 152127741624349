import React, { useCallback, useState, useEffect, FunctionComponent } from 'react';
import { useToken } from 'integrations/crossborderit';
import { Page, AppProvider, Button, Card, Banner, List, Frame, Loading, ResourceList, TextContainer, Stack, Layout, Collapsible, Link, Tabs, DataTable } from '@shopify/polaris';
import { OverviewStatus } from 'store/dashboard';
import { Switch, Case } from 'components/shared';
import NumberFormat from 'react-number-format';

export const Dashboard = () => {
    const token = useToken();
    let [overviewStatus, setOverviewStatus] = useState<OverviewStatus>();

    useEffect(() => {
        if (token !== undefined)
            getOverview()
    }, [token]);


    const getOverview = (() => {



        const fetchOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            }
        }

        fetch(`api/Dashboard/GetOverview`, fetchOptions)
            .then(response => response.json())
            .then((data) => {

                setOverviewStatus(data)
            })
    });

    const [selected, setSelected] = useState(0);



    // const rows = [
    //   ['Fulfilled', 37, 1021.97],
    //   ['Not fulfilled', 14, 333.15],
    //   ['Reported', 15, 327.03],
    //   ['Not Reported', 22, 694.94],
    // ]; 

    const rows = [
        ['Not fulfilled orders', <NumberFormat value={overviewStatus?.orderCounters.notFulfilledCount ?? 0} thousandSeparator={' '} displayType={'text'} />, <NumberFormat value={overviewStatus?.orderCounters.notFulfilledValue ?? 0} thousandSeparator={' '} suffix=" €" displayType={'text'} decimalScale={2} fixedDecimalScale={true} />],
        ['Pending new orders', <NumberFormat value={overviewStatus?.orderCounters.notReportedOrdersCount ?? 0} thousandSeparator={' '} displayType={'text'} />, <NumberFormat value={overviewStatus?.orderCounters.notReportedOrdersValue ?? 0} thousandSeparator={' '} suffix=" €" displayType={'text'} decimalScale={2} fixedDecimalScale={true} />],
        ['Pending updated orders', <NumberFormat value={overviewStatus?.orderCounters.notReportedUpdatedOrdersCount ?? 0} thousandSeparator={' '} displayType={'text'} />, <NumberFormat value={overviewStatus?.orderCounters.notReportedUpdatedOrdersValue ?? 0} thousandSeparator={' '} suffix=" €" displayType={'text'} decimalScale={2} fixedDecimalScale={true} />],
        ['Reported orders', <NumberFormat value={overviewStatus?.orderCounters.reportedOrderCount ?? 0} thousandSeparator={' '} displayType={'text'} />, <NumberFormat value={overviewStatus?.orderCounters.reportedOrderValue ?? 0} thousandSeparator={' '} suffix=" €" displayType={'text'} decimalScale={2} fixedDecimalScale={true} />],

    ];


    return (


        <Page>
            <Layout>
                <Layout.Section>

                    <Card title="Account information" sectioned>
                        <p>Connected to Crossborderit account: <strong>{overviewStatus?.settings?.cbitCompanyName}</strong></p>
                        <p>Your IOSS number: <strong>{overviewStatus?.settings.iossNumber}</strong></p>
                        <p>First IOSS date: <strong>{overviewStatus?.settings.iossStartDate}</strong></p>
                        <p>On error, email to: <strong>{overviewStatus?.settings.emailOnErrors}</strong></p>
                    </Card>
                    <Card title="Reporting" sectioned>
                        {
                            overviewStatus?.validInstallation
                                ? <p>All reporting to CBIT happens every 15 minutes, for more details, visit <Link url="https://cbit.crossborderit.com" external={true} id='crossborderit'>Crossborderit portal</Link></p>
                                : <p>Your setup and/or registration is not yet completed. Once it's done, the reporting will start automatically</p>
                        }
                    </Card>
                    <Card title="Statistics" sectioned>
                        <p>All statistics for this application is now moved to <Link url="https://cbit.crossborderit.com" external={true} id='crossborderit'>Crossborderit portal</Link></p>
                        {/*<DataTable*/}
                        {/*  columnContentTypes={[*/}
                        {/*    'text',*/}
                        {/*    'numeric',*/}
                        {/*    'numeric'*/}
                        {/*  ]}*/}
                        {/*  headings={[*/}
                        {/*    'Order status',*/}
                        {/*    'Count',*/}
                        {/*    'Collected VAT'*/}
                        {/*  ]}*/}
                        {/*  rows={rows}*/}
                        {/*  totals={['',*/}
                        {/*  <NumberFormat value={(overviewStatus?.totalOrderCount ?? 0 )} */}
                        {/*    thousandSeparator={' '} displayType={'text'} />,*/}
                        {/*  <NumberFormat value={(overviewStatus?.totalOrderValue ?? 0)} */}
                        {/*    thousandSeparator={' '} suffix=" €" displayType={'text'} decimalScale={2} fixedDecimalScale={true}/>]}*/}
                        {/*/>*/}
                        {/* totals={['Totals', 51, '€1,355.12']} */}

                    </Card>
                </Layout.Section>


                {/* <Case match='11'>
              <Layout.Section>
                <Banner title={'Order info will be shown here.'}
                  status="info"  >

                  <p>Meanwhile, visit <Link url="https://cbit.crossborderit.com" external={true} id='crossborderit'>Crossborderit portal</Link></p>
                </Banner>
              </Layout.Section>
              </Case>


              <Case match='12'>
              <Layout.Section>
                <Banner title={'Financial info will be shown here'}
                  status="info" >

                  <p>Meanwhile, visit <Link url="https://cbit.crossborderit.com" external={true} id='crossborderit'>Crossborderit portal</Link></p>
                </Banner>
              </Layout.Section>
              </Case> */}





            </Layout >
        </Page >


    );
}

import React, { useState, useEffect, FunctionComponent } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { Link, Redirect, useLocation } from 'react-router-dom';
import {
  ApplicationState,
  LoadingStatus,
  AppStatusState,
  actionCreators
} from 'store';
import { useToken } from 'integrations/crossborderit';
import { Switch, Case } from 'components/shared';

enum TokenStatus {
  NoTokenProvided,
  TokenProvided
}

export const TokenGuard: FunctionComponent = ({
  children
}) => {
  const token = useToken();
  const [tokenStatus, setTokenStatus] = useState(TokenStatus.NoTokenProvided);

  useEffect(() => {

    const tokenStatus = token
      ? TokenStatus.TokenProvided
      : TokenStatus.NoTokenProvided;

    setTokenStatus(tokenStatus);

  }, [token]);

  return (
    <Switch on={tokenStatus}>

      <Case match='0'>
      <p></p>
      </Case>

      <Case match='1'>
        {children}
      </Case>

    </Switch>
  )
}

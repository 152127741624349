import * as React from 'react';
import { Route, Switch as RouteSwitch } from 'react-router';
import Home from './components/Home';
import {Failed} from './components/Failed';
import { Dashboard } from './components/Dashboard';


import './custom.css'

export default () => (
    <RouteSwitch>
      <Route exact path="/" component={Home} />
      <Route exact path="/failed" component={Failed} />
      <Route path="/dashboard" component={Dashboard} />
    </RouteSwitch>
);

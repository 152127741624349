import { METHODS } from 'http';
import { Action, Reducer } from 'redux';
import { AppThunkAction } from './';

export interface TokenState {
  token?: string;
}

interface SetToken {
  type: 'SET_TOKEN';
  token?: string;
}

type KnownAction = SetToken;

export const setToken = (token: string): SetToken => ({
  type: 'SET_TOKEN',
  token: token,
});

const getUnloadedState = (): TokenState => ({
  token: undefined,
});

export const tokenReducer: Reducer<TokenState> = (
  state: TokenState | undefined,
  incomingAction: Action
): TokenState => {

  if (state === undefined) {
    return getUnloadedState();
  }

  const action = (incomingAction || {}) as KnownAction;

  switch (action.type) {

    case 'SET_TOKEN':
      return {
        ...state,
        token: action.token,
      };

    default:
      return state;
  }

};
